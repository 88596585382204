// @ts-nocheck
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from 'firebase/auth'

// Initialize Firebase app
const firebaseConfig = {
  apiKey: "AIzaSyCHIWM5rvfdRJHmqillQk0i0y9pjXSm7oc",
  authDomain: "whuups-324b0.firebaseapp.com",
  databaseURL: "https://whuups-324b0.firebaseio.com",
  projectId: "whuups-324b0",
  storageBucket: "whuups-324b0.appspot.com",
  messagingSenderId: "580809782185",
  appId: "1:580809782185:web:ff221c7f98da0021c34ab8",
  measurementId: "G-M9SD9659MC"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// initializeApp({
//     apiKey: "AIzaSyCHIWM5rvfdRJHmqillQk0i0y9pjXSm7oc",
//     authDomain: "whuups-324b0.firebaseapp.com",
//     databaseURL: "https://whuups-324b0.firebaseio.com",
//     projectId: "whuups-324b0",
//     storageBucket: "whuups-324b0.appspot.com",
//     messagingSenderId: "580809782185",
//     appId: "1:580809782185:web:ff221c7f98da0021c34ab8",
//     measurementId: "G-M9SD9659MC"
// })

// export const customSignInWithPhoneNumber = phoneNumber =>
//   new Promise((resolve, reject) => {
//     const auth = getAuth()

//     const handleSignIn = () => {
//       console.log("Attempting to sign in with phone number:", phoneNumber)
//       signInWithPhoneNumber(auth, phoneNumber, window.appVerifier)
//         .then(confirmationResult => {
//           console.log("Sign-in successful:", confirmationResult)
//           resolve(confirmationResult)
//         })
//         .catch(error => {
//           console.error("Error during sign-in:", error)
//           // Handling specific error codes
//           switch (error.code) {
//             case 'auth/invalid-phone-number':
//               reject(new Error('The phone number is invalid. Please check the number and try again.'))
//               break
//             case 'auth/missing-phone-number':
//               reject(new Error('Phone number is required. Please provide a valid phone number.'))
//               break
//             case 'auth/too-many-requests':
//               reject(new Error('Too many requests. Please wait a few minutes before trying again.'))
//               break
//             case 'auth/quota-exceeded':
//               reject(new Error('SMS quota exceeded. Please try again later.'))
//               break
//             case 'auth/captcha-check-failed':
//               reject(new Error('Captcha check failed. Please try again.'))
//               break
//             case 'auth/invalid-app-credential':
//               reject(new Error('Invalid app credential. Please check your Firebase configuration and reCAPTCHA setup.'))
//               break
//             default:
//               reject(new Error('An unexpected error occurred. Please try again later.'))
//           }
//         })
//     }

//     if (window.appVerifier) {
//       handleSignIn()
//     } else {
//       window.appVerifier = new RecaptchaVerifier(
//         'recaptcha-container',
//         {
//           size: 'invisible',
//         },
//         auth,
//       )
//       window.appVerifier.render()
//         .then(() => {
//           console.log("reCAPTCHA rendered successfully")
//           handleSignIn()
//         }).catch(error => {
//           console.error("Error rendering reCAPTCHA:", error)
//           reject(new Error('Failed to render reCAPTCHA. Please refresh the page and try again.'))
//         })
//     }
//   })

export const customSignInWithPhoneNumber = phoneNumber =>
  new Promise((resolve, reject) => {
    const handleSignIn = () => {
      console.log("Attempting to sign in with phone number:", phoneNumber);

      // Custom phone number validation
      const isValidPhoneNumber = number => {
        const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format
        return phoneRegex.test(number);
      };

      if (!isValidPhoneNumber(phoneNumber)) {
        console.error("Invalid phone number format.");
        reject(new Error('The phone number is invalid. Please check the number and try again.'));
        return;
      }

      // Simulate successful sign-in
      console.log("Sign-in successful");
      resolve({ success: true, message: "Sign-in successful", phoneNumber });
    };

    // if (window.appVerifier) {
      handleSignIn();
    // } else {
    //   // Simulating a reCAPTCHA rendering process
    //   window.appVerifier = {
    //     render: () => Promise.resolve(), // Simulate reCAPTCHA rendering
    //   };
    //   window.appVerifier.render()
    //     .then(() => {
    //       console.log("Simulated reCAPTCHA rendered successfully");
    //       handleSignIn();
    //     })
    //     .catch(error => {
    //       console.error("Error rendering simulated reCAPTCHA:", error);
    //       reject(new Error('Failed to render simulated reCAPTCHA. Please refresh the page and try again.'));
    //     });
    // }
  });


export const signOutFirebase = () => {
  const auth = getAuth()
  return signOut(auth)
}
