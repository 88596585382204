import Report, { ReportModelConfig } from 'examples/LayoutContainers/Reports'
import useFeedReportData from 'layouts/feed-reports/data/feedReportTableData'

const ModelConfig: ReportModelConfig = {
  model: 'feed-reports',
  modelDisplay: 'talk',
  reportedModel: 'feeds',
  fields: {
    title: 'title',
    body: 'description',
    image: 'image',
    reportedId: 'feedId',
    reportedBy: 'user',
    reportedReason: 'content',
    reportedModel: 'feed',
  },
}

function Feeds() {
  // return  <p>test</p>
  
  return <Report config={ModelConfig} useReportData={useFeedReportData} />
}

export default Feeds
