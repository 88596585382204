// @ts-nocheck
import Card from '@mui/material/Card'
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import icons
import IconButton from '@mui/material/IconButton'; // IconButton for the eye icon
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import { setSignInError, setSignInSuccess, useAppController } from 'context'
import { feathersApp } from 'helpers'
import { customSignInWithPhoneNumber } from 'helpers/firebase'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material';
function Basic() {
  const navigate = useNavigate()
  const location = useLocation()
  const [appController, dispatch] = useAppController()
  const { error: apiError } = appController
  const [loading, setLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')
  void code // eslint
  const [confirm, setConfirm] = useState(null)
  const phoneRef = useRef('')
  const codeRef = useRef('')
  const from = location.state?.from?.pathname || '/admin-view/talks'
 
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  
    // Handle toggling the password visibility
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  // console.log('api err ',apiError)
  // Clear error when phone number input changes
  const handleChangePhoneNo = useCallback(event => {
    phoneRef.current = event.target.value
    setPhoneNumber(phoneRef.current)
    setSignInError(dispatch, null) // Clear error when phone number is changed
  }, [dispatch])

  // Clear error when code input changes
  const handleChangeCode = useCallback(event => {
    codeRef.current = event.target.value
    setCode(codeRef.current)
    setSignInError(dispatch, null) // Clear error when code is changed
  }, [dispatch])

  // const handleChangePhoneNo = useCallback(event => {
  //   phoneRef.current = event.target.value
  //   setPhoneNumber(phoneRef.current)
  // }, [])

  // const handleChangeCode = useCallback(event => {
  //   codeRef.current = event.target.value
  //   setCode(codeRef.current)
  // }, [])

  
  const signInWithPhoneNumber = useCallback(async () => {
    if (phoneRef.current) {
      setLoading(true)
      try {
        const confirmation = await customSignInWithPhoneNumber(phoneRef.current)
        if (!confirmation) {
          throw new Error('Login failed, please try again later')
        }
        console.log(confirmation,"csnhdcjsdnsjn");
        setLoading(false)
        if(confirmation.success == true){
          const phone = phoneRef.current;

// Ensure the phone number starts with '+', if not, add it
const formattedPhone = phone.startsWith('+') ? phone : `+${phone}`;

          // const params = {
          //   strategy: 'local',
          //   phone: formattedPhone,
          //   password: "123456" // Check if this is expected or needs to be a different value
          // };
          //   const res = await feathersApp.authenticate(params);
            const response = await fetch('https://api.whuups.com/checkAdminAvailable', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone: formattedPhone }),
    });

    const data = await response.json();
    console.log(data.user,"iiiiioooooo");
            if (!data.user.roles || !Array.isArray(data.user.roles) || data.user.roles.indexOf('admin') === -1) {
              console.error("Invalid phone number format.");
              reject(new Error('The phone number is invalid. Please check the number and try again.'));
              return;
            }else{
              setSignInError(dispatch, { message: '' });
              setConfirm(true); // 

            }
        }else{
          setConfirm(false)
        }
        
        console.log(JSON.stringify(confirmation),'dfhwkfhwjkfhwmkjh');
      } catch (error) {
        console.log(error,
          "how to cxome "
        );
        setSignInError(dispatch, {message:'The phone number is invalid. Please check the number and try again.'})
        setLoading(false)
      }
    }
  }, [dispatch])

  const getUser = useCallback(async () => {
    console.log('Phone number received:', phoneRef.current);
  
    if (phoneRef.current) {
      const phone = phoneRef.current;

// Ensure the phone number starts with '+', if not, add it
const formattedPhone = phone.startsWith('+') ? phone : `+${phone}`;

      const params = {
        strategy: 'local',
        phone: formattedPhone,
        password: codeRef.current // Check if this is expected or needs to be a different value
      };
     
      try {
        console.log(params,"params here");
        const res = await feathersApp.authenticate(params);
        console.log('Authentication response:', res);
  
        if (!res) {
          return res.status(401).json({
            success: false,
            message: 'Invalid password.',
          });
        }
  
        console.log('Access Token:', res.accessToken);
        setLoading(false);
        setSignInSuccess(dispatch, {
          accessToken: res.accessToken,
          ...res.user,
        });
        navigate(from, { replace: true });
      } catch (err) {
        console.error('Authentication failed:', err);
        setLoading(false); // Ensure loading state is reset
        setSignInError(dispatch,  new Error('Invalid password'));
      }
    } else {
      console.error('Phone number is not available.');
      setSignInError(dispatch, new Error('Please try again later'));
    }
  }, [dispatch, from, navigate]);
  
  const handleConfirmCode = useCallback(async () => {
    setSignInError(dispatch, null); // Clear the error message
    if (!confirm) {
      setSignInError(dispatch, new Error('Please try again later'));
      return;
    }
  
    console.log("Attempting to confirm code:", codeRef.current);
  
    if (codeRef.current?.length > 0) {
      setLoading(true);
  
      try {
        // Simulate confirmation logic
       
        const userData = await getUser();
        console.log("Fetched profile successfully:", userData);
  
        // Additional logic for successful sign-in can go here...
  
      } catch (err) {
        console.error("Error during Password confirmation:", err);
        setSignInError(dispatch, err);
      } finally {
        setLoading(false);
      }
  
    } else {
      setSignInError(dispatch, new Error('Please enter a valid Password'));
    }
  }, [confirm, dispatch]);
  


  // const handleConfirmCode = useCallback(async () => {
  //   if (!confirm) {
  //     return setSignInError(dispatch, new Error('Please try again later'));
  //   }
  
  //   console.log("Attempting to confirm code:", codeRef.current);
  
  //   if (codeRef.current?.length === 6) {
  //     setLoading(true);
  //     try {
  //       // const userCredential = await confirm.confirm(codeRef.current);
  //       const userCredential = codeRef.current == "111111"?true:false;
        
  //       console.log("User successfully signed in:", userCredential);
        
  //       const getuserData = await getUser();  // Assuming this is a backend call to fetch user data\
  //       console.log("fecth profile successfully",getuserData);
  
  //     } catch (err) {
  //       console.error("Error during code confirmation:", err);
  //       setSignInError(dispatch, err);
  //       setLoading(false);
  //     }
  //   } else {
  //     setSignInError(dispatch, new Error('Please set a valid code'));
  //   }
  // }, [confirm, dispatch]);
  



  // useEffect(() => {
  //   feathersApp
  //     .reAuthenticate()
  //     .then(res => {
  //       setLoading(false)
  //       if (
  //         !res.user.roles ||
  //         (Array.isArray(res.user.roles) &&
  //           res.user.roles.indexOf('admin') === -1)
  //       ) {
  //         throw new Error('Unauthorized')
  //       }

  //       setSignInSuccess(dispatch, {
  //         accessToken: res.accessToken,
  //         ...res.user,
  //       })
  //       navigate('/', { replace: true })
  //     })
  //     .catch(err => {
  //       setLoading(false)
  //     })
  // }, [dispatch, navigate])

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2} sx={{ display: Boolean(confirm) ? 'block' : 'none' }}>
            <TextField
  type={showPassword ? 'text' : 'password'}
  label="Password"
  defaultValue={null}
  disabled={loading}
  inputProps={{
    maxLength: 13,
    pattern: '[a-z]{6}@\\d{6}',
  }}
  onChange={handleChangeCode}
  fullWidth
  InputProps={{
    endAdornment: (
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        edge="end"
      >                                                         
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    ),
  }}
/>


            </MDBox>
            <MDBox mb={2} sx={{ display: Boolean(confirm) ? 'none' : 'block' }}>
              <MDInput
                type='text'
                label='Phone Number'
                defaultValue={phoneNumber}
                disabled={loading}
                onChange={handleChangePhoneNo}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant='h6' color='error'>
                {Boolean(apiError)  ? apiError.message : ''}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant='gradient'
                color='info'
                fullWidth
                onClick={
                  Boolean(confirm) ? handleConfirmCode : signInWithPhoneNumber
                }
                disabled={loading}
              >
                {Boolean(confirm) ? 'confirm' : 'sign in'}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default Basic
