import { Query } from '@feathersjs/client'
import { useCallback, useEffect, useRef, useState } from 'react'

import { feathersApp } from './feather'

export function useIsMounted() {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return useCallback(() => isMounted.current, [])
}

export function useQuery(
  model: string,
  skip: number,
  limit: number,
  query?: Query,
) {
  console.log('modle skip limit quesry',model,skip,limit,query)
  const isMounted = useIsMounted()
  const service = useRef(feathersApp.service(model))
  const [fetching, setFetching] = useState(false)
  const [result, setResult] = useState<Array<any> | null>(null)
  const [total, setTotal] = useState<number | null>(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    if (isMounted()) {
      setFetching(true)
      const _query = Boolean(query)
        ? query
        : {
            query: {
              $skip: skip,
              $limit: limit,
              $sort: {
                createdAt: -1,
              },
            },
          }

      service.current
        .find(_query)
        .then(res => {
          console.log('query fetch outside',res)
          if (Array.isArray(res)) {
            console.log("here res",res);
            isMounted() && setResult(res)
          } else if (Array.isArray(res.data)) {
            console.log('query fetch else',res)
            if (isMounted()) {
              setResult(res.data)
              setTotal(res.total)
            }
          }
        })
        .catch(err => {
          console.log('query fetch err',err)
          isMounted() && setError(err)})
        .finally(() => isMounted() && setFetching(false))
    }
  }, [model, query])

  return {
    fetching,
    result,
    total,
    error,
    setFetching,
    setResult,
    setTotal,
    setError,
  }
}
